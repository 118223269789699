.datenschutz-text {
    margin: 0 5vh;
}

.datenschutz-text h2 {
    margin-bottom: 0;
}

.datenschutz-text h3 {
    margin-bottom: 0;
}